<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">

        <PaginatedDataTable v-model:model-value="richieste" :show-search="false"
                            :title="t('label.soggetto.richieste', 2)">

          <template #columns>
            <Column field="ragioneSocialeRichiedente" :header="t('label.soggetto.ragsoc')" :sortable="true"/>
            <Column field="eoriRichiedente" :header="t('label.soggetto.piva')" :sortable="true"/>

            <Column class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-check" class="p-button-primary p-mr-2" @click="confirmRequest(slotProps.data)"
                        v-tooltip.top="t('action.confirm')"/>
              </template>
            </Column>

          </template>
        </PaginatedDataTable>

      </div>
    </div>
  </div>

  <ConfirmDialog/>
</template>

<script setup>
import PaginatedDataTable from "@/components/common/datatable/PaginatedDataTable";
import {useI18n} from "vue-i18n";
import {onMounted, ref} from "vue";
import soggettoRichiesteRestService from "@/services/SoggettoRichiesteRestService";
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";
//import useMessage from "@/composable/useMessage";

const {t} = useI18n();
//const {success} = useMessage();
const confirm = useConfirm();
const toast = useToast();

const richieste = ref([]);

onMounted(async () => {
  await loadListaRichieste();
})

async function confirmRequest(data) {
  console.log("Confirm request ", data);

  confirm.require({
    header: t('label.soggetto.richiesta.titolo-messaggio', {company_name: data.ragioneSocialeRichiedente}),
    message: t('label.soggetto.richiesta.messaggio-conferma', {company_name: data.ragioneSocialeRichiedente}),
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      await soggettoRichiesteRestService.confirm(data.eoriRichiedente, data.eoriDestinatario);
      await loadListaRichieste();
      toast.add({severity: 'info', summary: t('label.confirmed'), detail: t('label.confirmed.extended'), life: 3000});
    },
    reject: async () => {
      await soggettoRichiesteRestService.reject(data.eoriRichiedente, data.eoriDestinatario);
      await loadListaRichieste();
      toast.add({severity: 'error', summary: t('label.rejected'), detail: t('label.rejected.extended'), life: 3000});
    }
  });

}

async function loadListaRichieste() {
  richieste.value = await soggettoRichiesteRestService.findForMe();
}

</script>

<style scoped>

</style>
