import httpClient from "@/services/HttpService";

export default {

    findForMe: async () => {
        return (await httpClient.get(`/soggetto/richieste/destinatario/me`)).data;
    },
    sendInvitation: (invito) => {
        return httpClient.post('/soggetto/richieste/invito', invito).data;
    },
    confirm: async (richiedente, destinatario) => {
        return (await httpClient.get(`/soggetto/richieste/accept/${richiedente}/destinatario/${destinatario}`)).data;
    },
    reject: async (richiedente, destinatario) => {
        return (await httpClient.get(`/soggetto/richieste/reject/${richiedente}/destinatario/${destinatario}`)).data;
    }

}
